@font-face {
font-family: 'tofinoText';
src: url(/_next/static/media/8242b3a1ac969a25-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: semibold;
}

@font-face {
font-family: 'tofinoText';
src: url(/_next/static/media/1ae10f0bc82dc8af-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: bold;
}

@font-face {
font-family: 'tofinoText';
src: url(/_next/static/media/c3590d8a059006a7-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: 'tofinoText Fallback';src: local("Arial");ascent-override: 86.20%;descent-override: 21.55%;line-gap-override: 0.00%;size-adjust: 111.37%
}.__className_b3edf1 {font-family: 'tofinoText', 'tofinoText Fallback'
}.__variable_b3edf1 {--font-tofino-text: 'tofinoText', 'tofinoText Fallback'
}

@font-face {
font-family: 'tofinoWide';
src: url(/_next/static/media/5148d678b7bb8128-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: semibold;
}

@font-face {
font-family: 'tofinoWide';
src: url(/_next/static/media/0d1e9e691b2b419a-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 900;
font-style: black;
}@font-face {font-family: 'tofinoWide Fallback';src: local("Arial");ascent-override: 76.58%;descent-override: 18.77%;line-gap-override: 0.00%;size-adjust: 125.75%
}.__className_72e82b {font-family: 'tofinoWide', 'tofinoWide Fallback'
}.__variable_72e82b {--font-tofino-wide: 'tofinoWide', 'tofinoWide Fallback'
}

